<template>
  <div :class="computedClass">
      <span>{{getText()}}</span>
  </div>
</template>

<script>
import * as reportConstants from "../../../../utils/constants";
export default {
  name: "ReportType",
  props: {
    type: {
      type: String,
      required: true,
    }
  },
  methods: {
      getText(){
          switch(this.type) {
                case reportConstants.DAILY_REPORT:
                    return "D";
                case reportConstants.MONTHLY_REPORT:
                    return "M";
                case reportConstants.YEARLY_REPORT:
                    return "Y";
                default: return "";
          }
      }
  },
  computed: {
      computedClass() {
            return  "report-type " + this.type.toLowerCase();
      }
  }
};
</script>

<style lang="scss" scoped>
.report-type{
    height: 23px;
    min-width: 23px;
    width: 23px;
    border-radius: 50%;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12%;
    background: #fff;
    font-size: 13px;
    &.daily{
        border-color: #17C59B;
        color: #17C59B;
    }
     &.monthly{
        border-color: #B019D6;
        color: #B019D6;
    }
     &.yearly{
        border-color: #FF8B4A;
        color: #FF8B4A;
    }
}
</style>