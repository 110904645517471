<template>
  <div class="input-root" :data-cy="dataCy">
    <div
      class="input-area"
      :class="{
        focused: focused || value,
        error: hasError,
        noLeadingIcon: !hasLeadingIcon,
        disabled,
      }"
    >
      <div class="leadingIcon">
        <slot name="leadingIcon" />
      </div>
      <label>
        {{ label }}
      </label>
      <input
        v-bind="$attrs"
        :value="value"
        @input="$emit('input', $event.target.value)"
        @focus="focused = true"
        @blur="onBlur"
        :disabled="disabled"
      />
      <div class="trailingIcon">
        <slot name="trailingIcon" />
      </div>
    </div>
    <p :class="{ errorMessage: hasError }" class="inputHelper">
      {{ helperText }}
    </p>
  </div>
</template>

<script>
export default {
  name: "SimpleFormInput",
  props: {
    value: {
      type: [String, Number],
      description: "Value of the input",
    },
    label: {
      type: String,
      description: "Label describing the value that should be written in",
    },
    hasError: {
      type: Boolean,
      description: "Whether the input is wrong",
    },
    helperText: {
      type: String,
      description: "Additional information for the input state/value",
      default: "",
    },
    disabled: {
      type: Boolean,
      description: "Whether thi input is disabled",
    },
    dataCy: {
      type: String,
      description: "Cypress selector",
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    hasLeadingIcon() {
      return !!this.$slots.leadingIcon;
    },
  },
  methods: {
    onBlur() {
      this.focused = false;
      this.$emit("blur");
    },
  },
};
</script>

<style lang="scss" scoped>
.input-area {
  min-height: toRem(36);
  position: relative;
  border: 1px solid $separator;
  border-radius: $inputRadius;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  justify-content: stretch;
  background-color: white;
  &.noLeadingIcon {
    label {
      left: 10px;
    }
  }
  &.error {
    background-color: $errorRed;
    border: 1px solid $errorRedBorder;
    label {
      background-color: $errorRed;
    }
  }
  .leadingIcon,
  .trailingIcon {
    margin-right: 4px;
    svg {
      fill: #8a8a8a;
    }
  }
  .trailingIcon {
    margin-left: auto;
  }
  input {
    border: none;
    background-color: transparent;
    z-index: 2;
    flex-basis: 100%;
    width: 100%;
  }
  label {
    margin-bottom: 0;
    top: 8px;
    left: 40px;
    position: absolute;
    transition: all 0.5s;
    color: #8a8a8a;
  }
  &.focused {
    &.error{
      label{
        background: linear-gradient(to bottom, #fff 50%, #ffeeee 50%);
      }
    }
    border: 1px solid $blueBorders;
    transition: border 0.5s;
    label {
      top: -10px;
      left: 8px;
      background-color: white;
      border-radius: 2px;
      padding: 0 2px;
    }
  }
  &.disabled {
    background-color: $disabled;
    label {
      background-color: $disabled;
    }
  }
}

.inputHelper {
  min-height: toRem(12);
  font-size: toRem(8);
  margin-bottom: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>