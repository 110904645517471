<template>
  <select-box
    :options="availableLanguages"
    v-model="currentLanguage"
    :clearable="false"
    :searchable="false"
    label="name"
  />
</template>

<script>
import SelectBox from "../inputs/SelectBox.vue";
import { TIMEFRAMES } from "../../utils/constants";
import { mapMutations } from "vuex";
export default {
  name: "languages-form",
  components: { SelectBox },
  data() {
    return {
      availableLanguages: this.$i18n.availableLocales.map((locale) => ({
        code: locale,
        name: this.$i18n.t(`locales.${locale}`),
      })),
      currentLanguage: {
        code: this.$i18n.locale,
        name: this.$i18n.t(`locales.${this.$i18n.locale}`),
      },
    };
  },
  methods: {
    ...mapMutations("chartStore", ["setTimeFrames"]),
  },
  watch: {
    currentLanguage: function (newLang) {
      this.$cookies.set("locale", newLang.code, "3y");
      this.$i18n.locale = newLang.code;
      this.setTimeFrames(TIMEFRAMES());
    },
  },
};
</script>

<style>
</style>