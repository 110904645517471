<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form
      @submit.prevent="handleSubmit(handleFormSubmition)"
      id="billing-info-form"
    >
      <b-row>
        <b-col cols="12">
          <h3>{{ $t("billingInfoForm.customer") }}</h3>
        </b-col>
        <b-col cols="12" xl="6">
          <ValidationProvider rules="required" v-slot="{ failed, errors }">
            <simple-form-input
              type="text"
              v-model="first_name"
              :label="$t('billingInfoForm.firstNameLabel')"
              :hasError="failed"
              :helperText="errors[0]"
            />
          </ValidationProvider>
        </b-col>
        <b-col cols="12" xl="6">
          <ValidationProvider rules="required" v-slot="{ failed, errors }">
            <simple-form-input
              type="text"
              v-model="last_name"
              :label="$t('billingInfoForm.lastNameLabel')"
              :hasError="failed"
              :helperText="errors[0]"
            />
          </ValidationProvider>
        </b-col>
        <b-col cols="12">
          <ValidationProvider
            :rules="{
              isValid: {
                val: phoneDetails ? phoneDetails.isValid || !phone : false,
              },
            }"
            mode="eager"
            v-slot="{ failed, validate }"
          >
            <vue-phone-number-input
              v-model="phone"
              no-example
              size="sm"
              color="#3687ff"
              @update="setPhoneDetails"
              no-validator-state
              :class="{ hasValue: !!phone, hasError: failed }"
              @phone-number-blur="validate"
              :translations="{
                countrySelectorLabel: $t(
                  'general.phoneInput.countrySelectorLabel'
                ),
                countrySelectorError: $t(
                  'general.phoneInput.countrySelectorError'
                ),
                phoneNumberLabel: $t('general.phoneInput.phoneNumberLabel'),
                example: $t('general.phoneInput.example'),
              }"
              :no-use-browser-locale="true"
            />
          </ValidationProvider>
        </b-col>
        <b-col cols="12">
          <h3>{{ $t("billingInfoForm.address") }}</h3>
        </b-col>
        <b-col cols="12" xl="6">
          <ValidationProvider rules="required" v-slot="{ failed, errors }">
            <simple-form-input
              type="text"
              v-model="street"
              :label="$t('billingInfoForm.streetLabel')"
              :hasError="failed"
              :helperText="errors[0]"
            />
          </ValidationProvider>
        </b-col>
        <b-col cols="12" xl="6">
          <ValidationProvider rules="required" v-slot="{ failed, errors }">
            <simple-form-input
              type="text"
              v-model="city"
              :label="$t('billingInfoForm.cityLabel')"
              :hasError="failed"
              :helperText="errors[0]"
            />
          </ValidationProvider>
        </b-col>
        <b-col cols="12" xl="6">
          <ValidationProvider
            rules="validCountry||required"
            v-slot="{ failed, errors }"
          >
            <select-box
              :options="countries"
              label="name"
              v-model="country"
              :clearable="false"
              :helperText="errors[0]"
              :hasError="failed"
              :name="$t('billingInfoForm.country')"
            />
          </ValidationProvider>
        </b-col>
        <b-col cols="12" xl="6">
          <ValidationProvider rules="required" v-slot="{ failed, errors }">
            <simple-form-input
              type="text"
              v-model="postcode"
              :label="$t('billingInfoForm.postCodeLabel')"
              :hasError="failed"
              :helperText="errors[0]"
            />
          </ValidationProvider>
        </b-col>
        <b-col cols="12">
          <h3>{{ $t("billingInfoForm.company") }}</h3>
        </b-col>
        <b-col cols="12">
          <ValidationProvider name="isLeagalEntity">
            <block-switch v-model="isLegalEntity">
              {{ $t("billingInfoForm.isLegalEntityLabel") }}
            </block-switch>
          </ValidationProvider>
        </b-col>
        <b-col cols="12">
          <ValidationProvider
            v-slot="{ failed, errors }"
            :rules="{
              required: isLegalEntity,
            }"
          >
            <simple-form-input
              type="text"
              v-model="companyName"
              :label="$t('billingInfoForm.nameLabel')"
              :hasError="failed"
              :helperText="errors[0]"
            />
          </ValidationProvider>
        </b-col>
        <b-col>
          <ValidationProvider
            v-slot="{ failed, errors }"
            :rules="{
              required: isLegalEntity,
            }"
          >
            <simple-form-input
              cols="12"
              xl="6"
              type="text"
              v-model="CIN"
              :label="$t('billingInfoForm.CINLabel')"
              :hasError="failed"
              :helperText="errors[0]"
            />
          </ValidationProvider>
        </b-col>
        <b-col>
          <ValidationProvider
            v-slot="{ failed, errors }"
            :rules="{
              required: isLegalEntity,
            }"
          >
            <simple-form-input
              type="text"
              v-model="TIN"
              :label="$t('billingInfoForm.TINLabel')"
              :hasError="failed"
              :helperText="errors[0]"
              cols="12"
              xl="6"
            />
          </ValidationProvider>
        </b-col>
      </b-row>
      <slot name="formControls" />
    </form>
  </ValidationObserver>
</template>

<script>
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import BlockSwitch from "../inputs/BlockSwitch.vue";
import SelectBox from "../inputs/SelectBox.vue";
import SimpleFormInput from "../inputs/SimpleFormInput.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import countryList from "country-list";
export default {
  components: { SimpleFormInput, SelectBox, BlockSwitch, VuePhoneNumberInput },
  name: "BillingInfoForm",
  props: {
    defaultValues: {
      type: Object,
      description: "Default values for the form",
    },
  },
  data() {
    return {
      first_name: "",
      last_name: "",
      companyName: "",
      isLegalEntity: false,
      street: "",
      city: "",
      country: "",
      phone: "",
      CIN: "",
      TIN: "",
      postcode: "",
      countries: countryList.getData(),
      phoneDetails: null,
    };
  },
  methods: {
    setPhoneDetails(e) {
      this.phoneDetails = e;
    },
    handleFormSubmition() {
      this.$emit("submit", {
        first_name: this.first_name,
        last_name: this.last_name,
        companyName: this.companyName,
        isLegalEntity: this.isLegalEntity,
        street: this.street,
        city: this.city,
        country: this.country,
        phone: this.phoneDetails?.e164 || "",
        CIN: this.CIN,
        TIN: this.TIN,
        postcode: this.postcode,
      });
    },
  },
  beforeMount() {
    const {
      companyName,
      isLegalEntity,
      street,
      city,
      country,
      phone,
      CIN,
      TIN,
      first_name,
      last_name,
      postcode,
    } = this.defaultValues;
    this.first_name = first_name;
    this.last_name = last_name;
    this.companyName = companyName;
    this.isLegalEntity = isLegalEntity;
    this.street = street;
    this.city = city;
    this.country = country;
    this.phone = phone;
    this.CIN = CIN;
    this.TIN = TIN;
    this.postcode = postcode;
  },
};
</script>

<style>
</style>