<template>
  <validation-observer v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <b-row cols="1">
        <b-col>
          <validation-provider rules="required" v-slot="{ failed, errors }">
            <select-box
              v-model="reason"
              label="name"
              :options="options"
              :name="$t('userInfoAndSettings.deleteAccount.deleteReason')"
              :hasError="failed"
              :helperText="errors[0]"
            />
          </validation-provider>
        </b-col>
        <b-col v-if="reason && reason.id === 6">
          <label>{{
            $t("userInfoAndSettings.deleteAccount.specificReason")
          }}</label>
          <validation-provider>
            <textarea v-model="specificReason" />
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider v-slot="{ failed, errors }" rules="required">
            <simple-form-input
              autocomplete="new-password"
              v-model="password"
              :label="$t('userInfoAndSettings.deleteAccount.password')"
              type="password"
              :hasError="failed"
              :helperText="errors[0]"
            />
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p>
            {{ $t("userInfoAndSettings.deleteAccount.deactivateAccountInfo") }}
          </p>
          <b-button
            variant="danger"
            type="submit"
            :disabled="isDeactivatingAccount"
          >
            <b-spinner v-if="isDeactivatingAccount" small />
            {{ $t("userInfoAndSettings.deleteAccount.deactivateAccount") }}
          </b-button>
        </b-col>
      </b-row>
    </form>
  </validation-observer>
</template>

<script>
import { DELETE_ACC_OPTIONS } from "../../utils/constants";
import SelectBox from "../inputs/SelectBox.vue";
import SimpleFormInput from "../inputs/SimpleFormInput.vue";
import { mapGetters } from "vuex";
export default {
  components: { SimpleFormInput, SelectBox },
  data() {
    return {
      password: "",
      reason: null,
      options: DELETE_ACC_OPTIONS.map((opt) => ({
        ...opt,
        name: this.$i18n.t(
          `userInfoAndSettings.deleteAccount.reasons.${opt.name}`
        ),
      })),
      specificReason: "",
    };
  },
  methods: {
    onSubmit() {
      this.$emit("deactivationRequested", {
        password: this.password,
        reason: this.specificReason ? this.specificReason : this.reason.name,
      });
    },
  },
  computed: {
    ...mapGetters("profileStore", ["isDeactivatingAccount"]),
  },
};
</script>

<style>
</style>