var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"id":"billing-info-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleFormSubmition)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$t("billingInfoForm.customer")))])]),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('simple-form-input',{attrs:{"type":"text","label":_vm.$t('billingInfoForm.firstNameLabel'),"hasError":failed,"helperText":errors[0]},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}})]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('simple-form-input',{attrs:{"type":"text","label":_vm.$t('billingInfoForm.lastNameLabel'),"hasError":failed,"helperText":errors[0]},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}})]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":{
            isValid: {
              val: _vm.phoneDetails ? _vm.phoneDetails.isValid || !_vm.phone : false,
            },
          },"mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var failed = ref.failed;
          var validate = ref.validate;
return [_c('vue-phone-number-input',{class:{ hasValue: !!_vm.phone, hasError: failed },attrs:{"no-example":"","size":"sm","color":"#3687ff","no-validator-state":"","translations":{
              countrySelectorLabel: _vm.$t(
                'general.phoneInput.countrySelectorLabel'
              ),
              countrySelectorError: _vm.$t(
                'general.phoneInput.countrySelectorError'
              ),
              phoneNumberLabel: _vm.$t('general.phoneInput.phoneNumberLabel'),
              example: _vm.$t('general.phoneInput.example'),
            },"no-use-browser-locale":true},on:{"update":_vm.setPhoneDetails,"phone-number-blur":validate},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$t("billingInfoForm.address")))])]),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var failed = ref.failed;
            var errors = ref.errors;
return [_c('simple-form-input',{attrs:{"type":"text","label":_vm.$t('billingInfoForm.streetLabel'),"hasError":failed,"helperText":errors[0]},model:{value:(_vm.street),callback:function ($$v) {_vm.street=$$v},expression:"street"}})]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var failed = ref.failed;
            var errors = ref.errors;
return [_c('simple-form-input',{attrs:{"type":"text","label":_vm.$t('billingInfoForm.cityLabel'),"hasError":failed,"helperText":errors[0]},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('ValidationProvider',{attrs:{"rules":"validCountry||required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var failed = ref.failed;
            var errors = ref.errors;
return [_c('select-box',{attrs:{"options":_vm.countries,"label":"name","clearable":false,"helperText":errors[0],"hasError":failed,"name":_vm.$t('billingInfoForm.country')},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","xl":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var failed = ref.failed;
            var errors = ref.errors;
return [_c('simple-form-input',{attrs:{"type":"text","label":_vm.$t('billingInfoForm.postCodeLabel'),"hasError":failed,"helperText":errors[0]},model:{value:(_vm.postcode),callback:function ($$v) {_vm.postcode=$$v},expression:"postcode"}})]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$t("billingInfoForm.company")))])]),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"isLeagalEntity"}},[_c('block-switch',{model:{value:(_vm.isLegalEntity),callback:function ($$v) {_vm.isLegalEntity=$$v},expression:"isLegalEntity"}},[_vm._v(" "+_vm._s(_vm.$t("billingInfoForm.isLegalEntityLabel"))+" ")])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":{
            required: _vm.isLegalEntity,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var failed = ref.failed;
          var errors = ref.errors;
return [_c('simple-form-input',{attrs:{"type":"text","label":_vm.$t('billingInfoForm.nameLabel'),"hasError":failed,"helperText":errors[0]},model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=$$v},expression:"companyName"}})]}}],null,true)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"rules":{
            required: _vm.isLegalEntity,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var failed = ref.failed;
          var errors = ref.errors;
return [_c('simple-form-input',{attrs:{"cols":"12","xl":"6","type":"text","label":_vm.$t('billingInfoForm.CINLabel'),"hasError":failed,"helperText":errors[0]},model:{value:(_vm.CIN),callback:function ($$v) {_vm.CIN=$$v},expression:"CIN"}})]}}],null,true)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"rules":{
            required: _vm.isLegalEntity,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var failed = ref.failed;
          var errors = ref.errors;
return [_c('simple-form-input',{attrs:{"type":"text","label":_vm.$t('billingInfoForm.TINLabel'),"hasError":failed,"helperText":errors[0],"cols":"12","xl":"6"},model:{value:(_vm.TIN),callback:function ($$v) {_vm.TIN=$$v},expression:"TIN"}})]}}],null,true)})],1)],1),_vm._t("formControls")],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }