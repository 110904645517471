<template>
  <div class="block-switch-root" :class="{ disabled, isTrue: value }">
    <b-form-checkbox
      switch
      size="lg"
      v-bind="$attrs"
      :checked="value"
      @change="$emit('input', $event)"
      :disabled="disabled"
      ><slot />
    </b-form-checkbox>
  </div>
</template>

<script>
export default {
  name: "block-switch",
  props: {
    value: {
      type: Boolean,
      description: "Value of the input",
    },
    hasError: {
      type: Boolean,
      description: "Whether the input is wrong",
    },
    helperText: {
      type: String,
      description: "Additional information for the input state/value",
      default: "",
    },
    disabled: {
      type: Boolean,
      description: "Whether thi input is disabled",
    },
  },
  methods: {
    logEvent(e) {
      console.log(e);
    },
  },
};
</script>

<style lang="scss" scoped>
.block-switch-root {
  margin-bottom: toRem(12);
  border: 1px solid $separator;
  border-radius: $inputRadius;
  padding: toRem(3) toRem(8);
  background-color: white;
  &.disabled {
    background-color: $disabled;
  }
  &.isTrue {
    border-color: $blueBorders;
  }
}
</style>