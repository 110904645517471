<template>
  <div>
    <b-row>
      <b-col cols="12">
        <h1>{{ $t("userInfoAndSettings.sectionTitle") }}</h1>
      </b-col>
      <b-col cols="12" md="6" lg="4">
        <h2>{{ $t("userInfoAndSettings.billingTitle") }}</h2>
        <billing-info-form
          :defaultValues="user"
          @submit="handleUpdateBillingInfo"
        >
          <template v-slot:formControls>
            <b-button type="submit" :disabled="isUpdatingBillingInfo">
              <b-spinner small v-if="isUpdatingBillingInfo" />
              <span>{{ $t("userInfoAndSettings.billingUpdate") }} </span>
            </b-button>
          </template>
        </billing-info-form>
      </b-col>
      <b-col cols="12" md="6" lg="4">
        <b-row>
          <b-col cols="12">
            <h2>{{ $t("userInfoAndSettings.changePassTitle") }}</h2>
            <change-password-form @submit="handleChangePassword" />
          </b-col>
          <b-col cols="12" class="langs-container">
            <h2>{{ $t("userInfoAndSettings.languages") }}</h2>
            <languages-form />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="rows-v-gap">
      <b-col cols="12" lg="8">
        <h2 class="margin-bottom">{{ $t("userInfoAndSettings.deleteAccount.sectionTitle") }}</h2>
        <delete-account-form
          @deactivationRequested="handleAccountDeactivation"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import BillingInfoForm from "../../components/forms/BillingInfoForm.vue";
import ChangePasswordForm from "../../components/forms/ChangePasswordForm.vue";
import DeleteAccountForm from "../../components/forms/DeleteAccountForm.vue";
import LanguagesForm from "../../components/forms/LanguagesForm.vue";
import MainAlert from "../../components/alerts/MainAlert.vue";
export default {
  name: "user-info-and-settings",
  components: {
    ChangePasswordForm,
    BillingInfoForm,
    DeleteAccountForm,
    LanguagesForm,
  },
  methods: {
    ...mapActions("profileStore", [
      "changePassword",
      "updateBillingInfo",
      "deactivateAccount",
    ]),
    ...mapActions(["logIn"]),
    ...mapMutations("profileStore", ["setPasswordError"]),
    handleChangePassword(data) {
      this.changePassword(data)
        .then(() => {
          this.$toast.success({
            component: MainAlert,
            props: {
              message: this.$i18n.t(
                "userInfoAndSettings.passwordChangeSuccessful"
              ),
            },
          });
        })
        .catch((err) => {
          this.setPasswordError(err.response.data);
          this.$toast.error({
            component: MainAlert,
            props: {
              message: this.$i18n.t("userInfoAndSettings.passwordChangeError"),
            },
          });
        });
    },
    handleUpdateBillingInfo(data) {
      this.updateBillingInfo(data)
        .then(() => {
          this.$toast.success({
            component: MainAlert,
            props: {
              message: this.$i18n.t(
                "userInfoAndSettings.billingInfoChangeSuccessful"
              ),
            },
          });
        })
        .catch(() => {
          this.$toast.error({
            component: MainAlert,
            props: {
              message: this.$i18n.t(
                "userInfoAndSettings.billingInfoChangeError"
              ),
            },
          });
        });
    },
    handleAccountDeactivation(data) {
      this.deactivateAccount(data)
        .then(() => {
          this.$toast.success({
            component: MainAlert,
            props: {
              message: this.$i18n.t(
                "userInfoAndSettings.accountDeactivationSuccessful"
              ),
            },
          });
          this.$router.push("/deactivated");
        })
        .catch(() => {
          this.$toast.error({
            component: MainAlert,
            props: {
              message: this.$i18n.t(
                "userInfoAndSettings.accountDeactivationError"
              ),
            },
          });
        });
    },
  },
  computed: {
    ...mapGetters({ user: "getUser" }),
    ...mapGetters("profileStore", ["isUpdatingBillingInfo"]),
  },
};
</script>

<style lang="scss" scoped>
.langs-container {
  margin-top: toRem(83);
}
.margin-bottom{
  margin-bottom: 0.83rem;
}
</style>