var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-row',{attrs:{"cols":1}},[_c('b-col',[_c('validation-provider',{attrs:{"rules":"min:8|required","name":"OldPassword","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('simple-form-input',{attrs:{"type":"password","label":_vm.$t('changePasswordForm.oldPasswordLabel'),"hasError":failed || !!(_vm.apiErrors && _vm.apiErrors.old_password),"helperText":errors[0] ||
              (_vm.apiErrors.old_password &&
                _vm.$t(("validations." + (_vm.apiErrors.old_password[0])))) ||
              ''},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}})]}}],null,true)})],1),_c('b-col',[_c('validation-provider',{attrs:{"rules":{
            required: true,
            min: 8,
            regex: _vm.PASSWORD_REGEX,
          },"name":"NewPassword","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var failed = ref.failed;
return [_c('simple-form-input',{attrs:{"type":"password","autocomplete":"new-password","label":_vm.$t('changePasswordForm.newPasswordLabel'),"hasError":failed || !!(_vm.apiErrors && _vm.apiErrors.new_password1),"helperText":errors[0] ||
              (_vm.apiErrors.new_password1 && _vm.apiErrors.new_password1[0]) ||
              ''},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})]}}],null,true)})],1),_c('b-col',[_c('validation-provider',{attrs:{"rules":"min:8|required|password:@NewPassword","name":"newPasswordRepeated","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var failed = ref.failed;
return [_c('simple-form-input',{attrs:{"autocomplete":"new-password","type":"password","label":_vm.$t('changePasswordForm.newPassword2Label'),"hasError":failed || !!(_vm.apiErrors && _vm.apiErrors.new_password2),"helperText":errors[0] ||
              (_vm.apiErrors.new_password2 && _vm.apiErrors.new_password2[0]) ||
              ''},model:{value:(_vm.newPasswordRepeated),callback:function ($$v) {_vm.newPasswordRepeated=$$v},expression:"newPasswordRepeated"}})]}}],null,true)})],1)],1),_c('b-row',{attrs:{"cols":"1"}},[_c('b-col',[_c('b-button',{attrs:{"type":"submit","disabled":_vm.isChangingPassword}},[(_vm.isChangingPassword)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.$t("changePasswordForm.changePasswordBtn")))])],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }