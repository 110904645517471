var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-row',{attrs:{"cols":"1"}},[_c('b-col',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('select-box',{attrs:{"label":"name","options":_vm.options,"name":_vm.$t('userInfoAndSettings.deleteAccount.deleteReason'),"hasError":failed,"helperText":errors[0]},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}})]}}],null,true)})],1),(_vm.reason && _vm.reason.id === 6)?_c('b-col',[_c('label',[_vm._v(_vm._s(_vm.$t("userInfoAndSettings.deleteAccount.specificReason")))]),_c('validation-provider',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.specificReason),expression:"specificReason"}],domProps:{"value":(_vm.specificReason)},on:{"input":function($event){if($event.target.composing){ return; }_vm.specificReason=$event.target.value}}})])],1):_vm._e(),_c('b-col',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('simple-form-input',{attrs:{"autocomplete":"new-password","label":_vm.$t('userInfoAndSettings.deleteAccount.password'),"type":"password","hasError":failed,"helperText":errors[0]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',[_c('p',[_vm._v(" "+_vm._s(_vm.$t("userInfoAndSettings.deleteAccount.deactivateAccountInfo"))+" ")]),_c('b-button',{attrs:{"variant":"danger","type":"submit","disabled":_vm.isDeactivatingAccount}},[(_vm.isDeactivatingAccount)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("userInfoAndSettings.deleteAccount.deactivateAccount"))+" ")],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }