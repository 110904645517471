<template>
  <validation-observer v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <b-row :cols="1">
        <b-col>
          <validation-provider
            rules="min:8|required"
            v-slot="{ failed, errors }"
            name="OldPassword"
            mode="lazy"
          >
            <simple-form-input
              type="password"
              v-model="oldPassword"
              :label="$t('changePasswordForm.oldPasswordLabel')"
              :hasError="failed || !!(apiErrors && apiErrors.old_password)"
              :helperText="
                errors[0] ||
                (apiErrors.old_password &&
                  $t(`validations.${apiErrors.old_password[0]}`)) ||
                ''
              "
            />
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            :rules="{
              required: true,
              min: 8,
              regex: PASSWORD_REGEX,
            }"
            v-slot="{ errors, failed }"
            name="NewPassword"
            mode="eager"
          >
            <simple-form-input
              type="password"
              autocomplete="new-password"
              v-model="newPassword"
              :label="$t('changePasswordForm.newPasswordLabel')"
              :hasError="failed || !!(apiErrors && apiErrors.new_password1)"
              :helperText="
                errors[0] ||
                (apiErrors.new_password1 && apiErrors.new_password1[0]) ||
                ''
              "
            />
          </validation-provider>
        </b-col>
        <b-col>
          <validation-provider
            rules="min:8|required|password:@NewPassword"
            v-slot="{ errors, failed }"
            name="newPasswordRepeated"
            mode="lazy"
          >
            <simple-form-input
              autocomplete="new-password"
              type="password"
              v-model="newPasswordRepeated"
              :label="$t('changePasswordForm.newPassword2Label')"
              :hasError="failed || !!(apiErrors && apiErrors.new_password2)"
              :helperText="
                errors[0] ||
                (apiErrors.new_password2 && apiErrors.new_password2[0]) ||
                ''
              "
            />
          </validation-provider>
        </b-col>
      </b-row>
      <b-row cols="1">
        <b-col>
          <b-button type="submit" :disabled="isChangingPassword">
            <b-spinner v-if="isChangingPassword" small />
            <span>{{ $t("changePasswordForm.changePasswordBtn") }}</span>
          </b-button>
        </b-col>
      </b-row>
    </form>
  </validation-observer>
</template>

<script>
import { PASSWORD_REGEX } from "../../utils/constants";
import SimpleFormInput from "../inputs/SimpleFormInput.vue";
import { mapGetters } from "vuex";

export default {
  components: { SimpleFormInput },
  name: "ChangePasswordForm",
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      newPasswordRepeated: "",
      PASSWORD_REGEX,
    };
  },
  computed: {
    ...mapGetters("profileStore", {
      isChangingPassword: "getChangingPasswordStatus",
      apiErrors: "getPasswordError",
    }),
  },
  methods: {
    onSubmit() {
      this.$emit("submit", {
        old_password: this.oldPassword,
        new_password1: this.newPassword,
        new_password2: this.newPasswordRepeated,
      });
    },
  },
};
</script>

<style>
</style>